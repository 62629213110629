import React from 'react'

const Footer = () => {
  return (
    <div className='faab-container'>
    <div className='footerCard'>
    Contact: faablabapp@gmail.com
    </div>
    </div>
  )
}

export default Footer